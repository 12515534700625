/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { getCoreRowModel, useReactTable, SortingState, ColumnDef, Row } from '@tanstack/react-table';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { ApiResponse } from '../../../models/ApiResponse';
import InfoIcon from '../icon/InfoIcon';
import { Heading, HeadingSize } from '../text/Heading';
import useSlot from '../../../hooks/useSlots';
import withSlot, { SlotDefinitions } from '../../../wrappers/withSlot';
import useDebounce from '../../../hooks/useDebounce';
import { SortDirection, sortExpression } from '../../../utils/ListUtils';
import { EventSystem } from '../../../events/EventSystem';
import Checkbox from '../form-control/Checkbox';
import { useDebugHotkey } from '../../../hooks/useDebugHotkey';
import { useItemSelection } from '../../../contexts/select-items/SelectItemsContext';
import useInfiniteScroll from '../../../hooks/useInfiniteScroll';
import DataTableLayout from './DataTableLayout';
import { SELECT_COLUMN_WIDTH, SELECT_COLUMN_ID } from '../../table-view/TableViewConstants';

const SIDEBAR_WIDTH = 72;
const TABLE_SIDE_PADDING = 20;

type TableProps = {
  dataId: string;
  columns: ColumnDef<any>[];
  queryFn: (filter: any) => Promise<ApiResponse<any>>;
  rowLinkProvider?: (id: string) => string;
  searchTerm?: string;
  extraQueryFilters?: Record<string, unknown>;
  enableMultiSelect?: boolean;
  title: string;
};

const DataTable = withSlot<TableProps, SlotDefinitions<['Empty']>>((props) => {
  const { dataId, columns, queryFn, extraQueryFilters, searchTerm, enableMultiSelect: enableExport, rowLinkProvider } = props;

  const { t } = useTranslation('data-table');
  const [recordSearchPhrase, setRecordSearchPhrase] = useState(searchTerm || '');
  const debouncedSearchTerm = useDebounce(recordSearchPhrase, 500);

  useEffect(() => {
    if (searchTerm !== undefined) {
      setRecordSearchPhrase(searchTerm);
    }
  }, [searchTerm]);

  const initialColumnSort: SortingState = [{ id: columns.find((x) => x.enableSorting)?.id || '', desc: true }].filter((x) => x.id);
  const getSortExpression = useCallback((columns: SortingState) => {
    return columns
      .map((column) => {
        const direction = column.desc ? SortDirection.Desc : SortDirection.Asc;
        return sortExpression(column.id, direction);
      })
      .join(',');
  }, []);

  const [sorting, setSorting] = useState<SortingState>(initialColumnSort);
  const [sortBy, setSortBy] = useState(getSortExpression(initialColumnSort));
  const [rowSelection, setRowSelection] = useState({});
  const { selection, mutateSelection, SelectAllButton } = useItemSelection();
  const debug = useDebugHotkey();

  useEffect(() => {
    setSortBy(getSortExpression(sorting));
  }, [getSortExpression, sorting]);

  const fetchDataOptions = {
    pageNumber: 1,
    pageSize: 15,
    sortBy: sortBy || undefined,
    searchTerm: debouncedSearchTerm || undefined,
    ...(extraQueryFilters || {}),
  };

  const {
    data: fetchedData,
    isLoading,
    isFetching,
    refetch,
    fetchNextPage,
  } = useInfiniteQuery({
    queryKey: [`data-${dataId}`, fetchDataOptions],
    initialPageParam: 1,
    queryFn: ({ pageParam = 1 }) => queryFn({ ...fetchDataOptions, pageNumber: pageParam }),
    getNextPageParam(lastPage) {
      return lastPage.hasNextPage ? (lastPage.pageNumber ?? 0) + 1 : undefined;
    },
  });

  const [infiniteLoadingRef] = useInfiniteScroll(fetchNextPage, isFetching);

  const data = useMemo(() => fetchedData?.pages.flatMap((x) => x.data ?? []) ?? [], [fetchedData?.pages]);

  useEffect(() => {
    const handler = () => refetch();
    EventSystem.listen('data-import-done', handler);
    return () => EventSystem.stopListening('data-import-done', handler);
  }, [refetch]);

  const defaultData = useMemo(() => [], []);

  const hasData = useMemo(() => data.length > 0, [data.length]);

  const initialColumnWidth = useMemo(
    () => Math.max(250, (window.innerWidth - SIDEBAR_WIDTH - TABLE_SIDE_PADDING * 2 - (enableExport ? SELECT_COLUMN_WIDTH : 0)) / columns.length),
    [columns.length, enableExport],
  );

  const selectRowColumn: ColumnDef<any> = {
    id: SELECT_COLUMN_ID,
    size: SELECT_COLUMN_WIDTH,
    enableResizing: false,
    header: ({ table }) => SelectAllButton({ visibleItems: table.getRowModel().rows.map((x) => x.original) }),
    cell: ({ row }: any) => (
      <div>
        <Checkbox
          {...{
            containerClassName: '!my-0',
            value: row.getIsSelected(),
            disabled: !row.getCanSelect(),
            onChange: (checked) => {
              mutateSelection.selectIndividual(row.original, checked);
            },
          }}
        />
      </div>
    ),
  };

  const table = useReactTable({
    data: data || defaultData,
    columns: enableExport ? [selectRowColumn, ...columns] : columns,
    columnResizeMode: 'onChange',
    defaultColumn: {
      size: initialColumnWidth,
    },
    enableColumnResizing: true,
    state: {
      sorting,
      rowSelection,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    manualSorting: true,
    enableSortingRemoval: false,
    enableRowSelection: true,
    enableMultiRowSelection: true,
    debugTable: debug,
  });

  useEffect(() => {
    setRowSelection(
      Object.assign(
        {},
        ...table.getRowModel().rows.map((row: Row<any>, i) => {
          return { [i]: selection.isSelected(row.original) };
        }),
      ),
    );
  }, [selection, table]);

  const EmptyElement = useMemo(
    () => (
      <div data-cy="list-empty" className="flex h-full w-full items-center justify-center">
        <div className="text-center">
          <InfoIcon className="bg-primary-1 text-primary-1 my-2 h-16 w-16 rounded-full bg-opacity-10 p-4" />
          <Heading size={HeadingSize.H3} className="my-4">
            {t('empty.heading')}
          </Heading>
        </div>
      </div>
    ),
    [t],
  );

  const EmptyPlaceholder = useSlot(props, 'Empty', EmptyElement);

  const scrollContainerRef = useRef<HTMLDivElement | null>(null);

  return (
    <div className="flex h-full max-w-full flex-col justify-between">
      <DataTableLayout
        table={table}
        hasData={hasData}
        isFetching={isFetching || isLoading}
        totalRecords={data?.length}
        setScrollContainerRef={(ref) => {
          scrollContainerRef.current = ref;
        }}
        infiniteLoadingRef={infiniteLoadingRef}
        rowLinkRoute={(row) => (rowLinkProvider ? rowLinkProvider(row.original.id) : '#')}
      >
        <DataTableLayout.Slot name="Empty">
          <EmptyPlaceholder />
        </DataTableLayout.Slot>
      </DataTableLayout>
    </div>
  );
});

export default DataTable;
